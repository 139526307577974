@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-wise{
    @apply flex items-center justify-center border border-solid border-neutral-400/40 rounded-lg p-2 bg-slate-100 hover:bg-slate-300/40;
  }
  .w-med-heading{
    @apply font-bold text-lg
  }
}

.-z-1 {
    z-index: -1;
  }

  .origin-0 {
    transform-origin: 0%;
  }

  .floating-inp div:has(input:focus) ~ label,
  .floating-inp div:has(input:not(:placeholder-shown)) ~ label,
  .floating-inp div:has(textarea:focus) ~ label,
  .floating-inp div:has(textarea:not(:placeholder-shown)) ~ label,
  .floating-inp div:has(select:focus) ~ label,
  .floating-inp div:has(select:not([value='']):valid) ~ label {
    /* @apply transform; scale-75; -translate-y-6; */
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
    --tw-translate-y: -1.5rem;
    background-color: #fff;
  }

  .floating-inp div:has(input:focus) ~ label,
  .floating-inp div:has(select:focus) ~ label {
    /* @apply text-black; left-0; */
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
    left: 0px;
  }