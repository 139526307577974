:root, [data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
* {
  font-family: "Poppins", sans-serif;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}




.wise-fire-dlg h2.swal2-title{
  font-size: 1rem;
  text-align: left;
  border-bottom: 1px solid #999;
  padding-bottom: 12px;
}
.wise-fire-dlg .swal2-html-container{
  text-align: left;
  margin-left: 1em;
}
.wise-fire-dlg .swal2-actions{
  width: 100%;
  justify-content: end;
  padding-right: .7em;
}
.wise-fire-dlg .swal2-actions button{
  outline: none;
  padding: .4em .8em;
}
.wise-fire-dlg .swal2-actions button.swal2-confirm{
  background-color: #027e6f;
}
.wise-fire-dlg .swal2-actions button.swal2-cancel{
  background-color: rgb(0 0 0 / 0.1);
  color: #000;
}

.wise-fire-dlg .small-title{
  font-size: 14px;
  font-weight: 900;
}
.wise-fire-dlg .sign-disclosure{
  color: #027e6f;
}

.wise-fire-dlg .small-body{
  display: inline-block;
  font-size: 14px;
  line-height: 1.6;
  text-align: justify;
}

.select {
  --tw-bg-opacity: 1;
  -webkit-appearance: none;
  appearance: none;
  background-color: oklch(1 0 0 / var(--tw-bg-opacity));
  background-color: var(--fallback-b1, oklch(var(--b1) / var(--tw-bg-opacity)));
  background-image: linear-gradient(45deg, #0000 50%, currentColor 0), linear-gradient(135deg, currentColor 50%, #0000 0);
  background-position: calc(100% - 20px) calc(1px + 50%), calc(100% - 16.1px) calc(1px + 50%);
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  border-color: #0000;
  border-radius: .5rem;
  border-radius: var(--rounded-btn, .5rem);
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  font-size: .875rem;
  height: 3rem;
  line-height: 1.25rem;
  line-height: 2;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  -webkit-user-select: none;
  user-select: none;
}
.select-primary, .select-primary:focus {
  --tw-border-opacity: 1;
  border-color: var(--fallback-p, oklch(var(--p) / var(--tw-border-opacity)));
}

.cover-info table{
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}
.cover-info table tr td{
  padding: 8px 0;
}
.td-label {
  font-weight: 600;
  padding: 3px 0;
}
.cover-info td.td-label {
  min-width: 120px;
}
.btn-xs {
  outline: 0;
  cursor: pointer;
  text-align: center;
  margin: 0;
  color: #000;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 9px;
  letter-spacing: 0;
  text-transform: uppercase;
  border: 1px solid #aaa;
  background: #fff;
  white-space: nowrap;
  line-height: 1.5;
}

:where(.rt-BaseTabListTrigger[data-state='active'], .rt-TabNavLink[data-active]) .rt-BaseTabListTriggerInner {
  font-weight: bold;
}

.calldata {
  border: 1px solid #999;
  border-radius: 5px;
  text-align: center;
  width: 28px;
  height: 28px;
  padding: 1px;
}
.calldata-day, .calldata-month {
  line-height: 1;
  padding-top: 2px;
  font-weight: 600;
  font-size: 10px;
}
.calldata-month {
  text-transform: uppercase;
}
.tbl-item-text-sub-item{
  color: #666;
    font-size: 12px;
    word-break: break-word;
}
.tbl-item-text-sub-item p{
  margin-top: 0;
  margin-bottom: 1rem;
}
.tbl-item-text-sub-item a{
  color: rgb(13,110,253);
  text-decoration: none;
}
.tbl-item-text-sub-item ul{
  margin-left: 2rem;
  margin-bottom: 1rem;
}
.tbl-item-text-sub-item ul li{
  list-style-type: circle;
}
.draft-wrapper-class {
  border: 1px solid #ccc;
}
.draft-editor-class {
  background-color:#faf8f8;
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 180px;
}
.draft-small-min{  
  min-height: 90px;
}

.form-group label:not(.error) {
  font-family: Montserrat;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
}
.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.0625rem + 2px);
  padding: .375rem .75rem;
  font-size: .875rem;
  font-weight: unset;
  line-height: 1.5;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-text {
  display: block;
  margin-top: .25rem;
}
.text-danger {
  color: #e74a3b !important;
}
.small, small {
  font-size: 80%;
  font-weight: 400;
}

.form-control[data-error=true]{
  color: #f86c6b;
  border-color: #f86c6b;
  padding-right: calc(1.5em + .75rem);
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

label[data-error=true]{
  color: #f86c6b;
}
select.form-control[multiple], select.form-control[size], textarea.form-control {
  height: auto;
}

span.status {
  display: inline-block;
}

.task-status {
  font-weight: 700;
  text-align: center;
  float: right;
  background: #bbb;
  color: #fff;
  padding: 3px 5px;
  border-radius: 20px;
  font-size: 9px;
  letter-spacing: 0;
  text-transform: uppercase;
  min-width: 60px;
}
.green {
  color: #76c329
}

.status.green {
  background: #75b52a;
  color: #fff;
}

.status.orange {
  background: #fda91e;
}

.status.red {
  background: #be141f;
  color: #fff;
}
.status.blue {
  background: #1b9cd2;
}

.status.gray {
  background: grey;
  color: #fff;
}

.success {
  background: #28a745!important;
  color: #fff;
}

.status.success {
  color: #fff
}
.discl-menu {
  border: 1px solid #aaa;
  float: none;
  background: #fff;
  padding: 2px 4px 2px 0;
  width: 8px;
  height: 8px;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  line-height: 7px;
  margin-right: 5px;
  cursor: pointer;
  width: 14px;
  height: 14px;
}
.discl-menu-caret {
  display: inline-block;
  color: #555;
  width: 0;
  height: 0;
  margin-left: 2px;
  margin-top: -2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.br-grid-menu ul {
  -moz-columns: 20px 2;
  columns: 20px 2;
  padding: 20px 10px 10px !important;
  background: #fff;
  color: #444;
  margin-left: -1px !important;
  z-index: 1800;
  display: none;
  position: absolute;
  width: 521px;
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  background-clip: padding-box;
  text-align: left;
}
.br-grid-menu ul li {
  display: block;
  margin-bottom: 10px;
  page-break-inside: avoid;
  -moz-column-break-inside: avoid;
  break-inside: avoid;
}
.br-grid-menu ul li a,
.br-grid-menu ul li span.link {
  outline: none;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  color: #444;
  display: block;
  text-decoration: none;
  line-height: 16px;
  padding: 5px;
  white-space: normal;
  border-bottom: unset;
  display: inline-block;
  cursor: pointer;
}
.br-grid-menu ul li a i,
.br-grid-menu ul li span.link i {
  clear: left;
  float: left;
  margin-right: 10px;
  font-size: 24px;
  display: block;
  text-align: center;
  line-height: 33px;
  color: #1b9cd2;
  text-shadow: 1px 1px 0 hsla(0, 0%, 100%, .7);
}
.br-grid-menu ul li a small,
.br-grid-menu ul li span.link small {
  color: #999;
  display: block;
  font-weight: 400;
  font-size: 12px;
}

.tbl-doc-act-empty-row-data-cont {
  padding-bottom: 0;
  padding-top: 10px;
  vertical-align: top;
  padding-right: 10px;
  position: relative;
  min-width: 30px;
}

.imp-doct-icon {
  font-size: 30px;
  color: #666;
  vertical-align: top;
  text-align: center;
}

.tbl-doc-act-empty-row-data-text {
  padding-top: 10px;
  vertical-align: top;
}
.tbl-item-text-sub-item {
  color: #666;
  font-size: 12px;
  word-break: break-word;
}
.status {
  min-width: 60px;
  font-weight: 700;
  text-align: center;
  background: #bbb;
  color: #fff;
  padding: 3px 5px;
  border-radius: 20px;
  font-size: 9px;
  letter-spacing: 0;
  text-transform: uppercase;
}

.wtag-container{
  display: flex;
  overflow: scroll;
  width: 100%;
  max-width: 100%;
  padding-left: 14px;
  border: 1px grey solid;
  border-radius: 5px;
  color: black
}
.wtag-container input{
  width: 100%;
  min-width: 50%;
  border: none;
  border-radius: 5px;
  padding: 14px;
  padding-left: 14px;
}
.wtag-container .tag{
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 5px;
  border: 1px solid orange;
  border-radius: 5px;
  background-color: orange;
  white-space: nowrap;
  color: white;
}

.wtag-container .tag button{
  display: flex;
  padding: 6px;
  border: none;
  background-color: unset;
  cursor: pointer;
  color: white;
}

.w-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: .35rem;

  margin-bottom: 1.5rem;
  text-align: center !important;
}
.w-card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
  min-height: 1px;
}
.w-card-body h6{
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1rem;
}
.w-card-body h3{
  margin-bottom: .5rem;
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.2;
}
.w-card-body small{
  font-size: 80%;
  font-weight: 400;
  color: #858796 !important;
}
.w-card-body h6 i{
  display: inline-block;
  font-size: 38px;
}


.color-amber {
  color: #ffc107 !important;
}


.w-card .header {
  color: #424242;
  padding: 20px;
  position: relative;
  box-shadow: none;
}
.w-card .header h2 {
  color: #757575;
  position: relative;
  font-size: 15px;
}
.w-card .header h2:before {
  position: absolute;
  width: 20px;
  height: 1px;
  left: 0;
  top: -20px;
  content: "";
  background: #49c5b6;
}
.w-card .header h2 strong {
  color: #49c5b6;
}

.w-card .body {
  font-weight: 400;
  color: #424242;
  padding: 20px;
}

.dropdown-toggle.not-drop::before,
.dropdown-toggle.not-drop::after{
  display: none;
}


.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.45rem 1.2rem;
  clear: both;
  font-weight: 400;
  color: #3a3b45;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.mat-dialog-container {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;

  max-width: 80vw;
  pointer-events: auto;
  width: 450px;
  position: static;
}
.mat-dialog-container {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
}
.mat-dialog-title {
  font: 500 20px / 32px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
}
.mat-dialog-title {
  margin: 0 0 10px;
  display: block;
}
.mat-dialog-content {
  display: block;
  margin: 0 -24px;
  padding: 0 24px;
  max-height: 65vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mat-dialog-actions {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  margin-bottom: -24px;
  justify-content: flex-end;
}

.mat-raised-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.mat-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mat-button-focus-overlay {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.35, 0, 0.25, 1),
    background-color 200ms cubic-bezier(0.35, 0, 0.25, 1);
}

.mat-button-ripple.mat-ripple,
.mat-button-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
}

.mat-button-focus-overlay {
  background: #000;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-button-ripple.mat-ripple,
.mat-button-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
}

.cdk-overlay-0 {
  max-width: 80vw;
  pointer-events: auto;
  width: 450px;
  position: static;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;

  justify-content: center;
  align-items: center;

  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {
  background-color: #3f51b5;
  color: #fff;
  font-weight: 900;
}

.mat-fab.mat-accent[disabled],
.mat-fab.mat-primary[disabled],
.mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled],
.mat-flat-button.mat-accent[disabled],
.mat-flat-button.mat-primary[disabled],
.mat-flat-button.mat-warn[disabled],
.mat-flat-button[disabled][disabled],
.mat-mini-fab.mat-accent[disabled],
.mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-warn[disabled],
.mat-mini-fab[disabled][disabled],
.mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-primary[disabled],
.mat-raised-button.mat-warn[disabled],
.mat-raised-button[disabled][disabled] {
  background-color: rgba(0, 0, 0, 0.12);
}

.md-card,
.md-card.md-default-theme {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff;
  border-radius: 2px;
}

.md-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.md-card .md-card-content {
  display: block;
  padding: 16px;
}


